import React, { useEffect, useState } from "react";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import Logo from "../assets/images/logo.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/spribe.png";
import ProviderLogo5 from "../assets/images/providers/netent.png";
import ProviderLogo6 from "../assets/images/providers/redtiger.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import { Col, Container, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import SportsIcon from "../assets/images/icons/sportsbook.svg";
import CasinoIcon from "../assets/images/icons/casino.svg";
import HomeIcon from "../assets/images/icons/home.svg";
import EzugiIcon from "../assets/images/icons/ezugi.svg";
import DepositIcon from "../assets/images/icons/deposit.svg";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = ["home", "betby", "casinogame", "ezugi", "gatewaylist"];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <footer className="footer">
        <Container className="max-1200w d-none">
          <div className="providers_sec ">
            <Row>
              <Col md={6} xs={12}>
                <div className="provider-mob-border">
                  <div className="heading mb-4">
                    <div className="text">
                      <h5>Top Providers</h5>
                      <p>
                        Play Your favorite Casino Games with{" "}
                        <span>Top Providers</span>
                      </p>
                    </div>
                  </div>

                  <div className="providers_logo d-flex flex-wrap justify-content-center">
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo2} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo3} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo4} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo5} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo6} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo7} alt="Provider Logo" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="">
                  <div className="heading payment-heading mb-4 ">
                    <div className="text">
                      <h5>Payment Gateway</h5>
                      <p>
                        Accept Process &amp; disburse{" "}
                        <span>Digital Payments </span>
                        for your bussiness
                      </p>
                    </div>
                  </div>

                  <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo1} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box payment-height">
                      <img src={PaymentLogo2} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo3} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo4} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box payment-height">
                      <img src={PaymentLogo5} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo6} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box payment-height">
                      <img src={PaymentLogo7} alt="Provider Logo" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="providers_img ">
            <a href="#">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="#">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="#">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="#">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links ">
            <ul>
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicy ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegame">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplay">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrule">Games Rules</a>
              </li>
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us{" "}
            </a>
          </div>

          {/* <div className="d-flex justify-content-center mt-2">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div> */}

          <div className="d-flex justify-content-center align-items-center mt-3 mb-3 ">
            <img
              src={Betfair}
              loading="lazy"
              className="mb-3 mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="#">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                className="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {/* <span className="notification-count">2</span> */}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
        </Container>

        <Container className="max-1200w">
          <Row>
            <Col md={3} xs={6} className="firstfooterlinks">
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Casino</h2>
                  <ul>
                    <li>
                      <a href="/casinogame">Casino</a>
                    </li>
                    <li>
                      <a href="/casinogame">TV Games</a>
                    </li>
                    <li>
                      <a href="/casinogame">Live Casino</a>
                    </li>
                    <li>
                      <a href="/casinogame">Casino Games</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>SiteMap</h2>
                  <ul>
                    <li>
                      <a href="/home">Home</a>
                    </li>

                    {/* <li>
                      <a href="#">About Us</a>
                    </li> */}
                    <li>
                      <a href="/gatewaylist">Deposit</a>
                    </li>
                    <li>
                      <a href="/withdraw">Withdraw Funds</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Policies</h2>
                  <ul>
                    <li>
                      <a href="/privacypolicy ">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/responsiblegame">Responsible Gaming</a>
                    </li>
                    <li>
                      <a href="/fairplay">Fair Play</a>
                    </li>
                    <li>
                      <a href="/gamesrule">Games Rules</a>
                    </li>
                    <li>
                      <a href="/terms">Terms and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Support</h2>
                  <ul>
                    <li>
                      <a href="/contactus">Contact Us</a>
                    </li>
                    {/* <li>
                      <a href="#">FAQ</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <div className="cryptoCurrencyWrapper">
            <a href="#" className="logo_box">
              <img src={PaymentLogo1} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box payment-height">
              <img src={PaymentLogo2} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PaymentLogo3} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PaymentLogo4} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box payment-height">
              <img src={PaymentLogo5} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PaymentLogo6} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box payment-height">
              <img src={PaymentLogo7} alt="Provider Logo" />
            </a>
          </div>

          <div className="providers_img">
            <a href="#">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="#">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="#">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="#">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-logo">
            <div className="logo">
              <a href="/">
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              </a>
            </div>

            <div className="copyright">
              © 2024 flybets.in | All Rights Reserved.
            </div>

            <div className="social-icons">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? appDetails?.WHATS_APP_URL
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {" "}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                    {/* <span className="notification-count">2</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div>

            <p>{appDetails?.FOOTER_TEXT || ""}</p>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-md-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  href="/sports/Inplay"
                  className={activePage === "sports/Inplay" ? "active" : ""}
                >
                  {/* <SportsIcon /> */}
                  <img src={SportsIcon} alt="sports" />
                  <span>Sports</span>
                </a>
              </li>
              <li>
                <a
                  href="/casinogame"
                  className={activePage === "casinogame" ? "active" : ""}
                >
                  <img src={CasinoIcon} alt="casino" />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  href="/home"
                  className={activePage === "home" ? "active" : ""}
                >
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  href={CasinoProviders["ezugi"]?.href}
                  className={activePage === "ezugi" ? "active" : ""}
                >
                  <img src={EzugiIcon} alt="ezugi" />
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  href="/gatewaylist"
                  className={activePage === "gatewaylist" ? "active" : ""}
                >
                  <img src={DepositIcon} alt="deposit" />
                  <span>Deposit</span>
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
