import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Accordion, Dropdown, Tab, Tabs } from "react-bootstrap";

import MenuIcon from "./../../assets/images/icons/sidebaricon/toggle.png";
import livecasino from "./../../assets/images/icons/sidebaricon/livecasino.png";
import poker from "./../../assets/images/icons/casino/Poker.png";
import Aviator from "./../../assets/images/icons/casino/Aviator.svg";
import promotion from "./../../assets/images/icons/sidebaricon/promotion.png";
import contactUs from "./../../assets/images/icons/sidebaricon/support.png";
import tvgame from "./../../assets/images/icons/sidebaricon/television.png";
import casinogame from "./../../assets/images/icons/sidebaricon/crown.png";
import favourite from "./../../assets/images/icons/sidebaricon/star.png";
import recent from "./../../assets/images/icons/sidebaricon/recent.png";
import topgames from "./../../assets/images/icons/sidebaricon/categories.png";
import providersicon from "./../../assets/images/icons/sidebaricon/responsible.png";
import virtualgame from "./../../assets/images/icons/virtualsports.png";

import EzugiIcon from "./../../assets/images/icons/sidebaricon/Ezugi.png";
import EvolutionIcon from "./../../assets/images/icons/sidebaricon/Evolution.png";
import VivoIcon from "./../../assets/images/icons/sidebaricon/VivoGaming.png";
import SupernowaIcon from "./../../assets/images/icons/sidebaricon/supernowa-logo.gif";
import PragamaticIcon from "./../../assets/images/icons/sidebaricon/ParagmatiPlay.png";
import onetouchIcon from "./../../assets/images/icons/sidebaricon/OneTouch.png";

import CasinoTabsIcon from "../../assets/images/icons/sidebaricon/blackjack.png";
import sportsTabsIcon from "../../assets/images/icons/sidebaricon/football.png";

import { APP_CONST } from "../../config/const";
import LoginModal from "../../views/components/authModals/LoginModal";
import MobileNumberModal from "../../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../../views/components/authModals/RegisterModal";

const BeforeLeftBar = ({ setShowAuthModal }) => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = ["casinogame", "sports"];

  const persistValue = localStorage.getItem("isSideBarOpen")
    ? localStorage.getItem("isSideBarOpen") === "true"
    : false;

  const [showSideBar, setShowSidebar] = useState(persistValue);

  useEffect(() => {
    if (showSideBar) {
      document.body.classList.add("sidebar_open");
    } else {
      document.body.classList.remove("sidebar_open");
    }
  }, [showSideBar]);

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleRedirection = (redirectionUrl, tabName) => {
    if (redirectionUrl) {
      localStorage.setItem("isSideBarOpen", true);
      if (tabName) {
        navigate(redirectionUrl, {
          state: {
            tabName: tabName ? tabName : "",
          },
        });
      } else {
        navigate(redirectionUrl);
      }
    }
  };

  const handleModalShow = () => {
    if (!isAuth) {
      setShowAuthModal && setShowAuthModal(true);
    }
  };

  return (
    <>
      <div
        className={
          showSideBar ? "show beforeleftsidebar" : " beforeleftsidebar"
        }
      >
        <ul className="show-beforesidebar">
          <div className="casino-sports-tabs">
            <div className="casino-sportsheader">
              <Button
                variant=""
                className="before-sidebar-menu"
                onClick={() => {
                  localStorage.setItem("isSideBarOpen", !showSideBar);
                  setShowSidebar(!showSideBar);
                }}
              >
                <img src={MenuIcon} />
              </Button>

              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    className={activePage == "casinogame" ? "active" : ""}
                    onClick={() =>
                      handleRedirection("/casinogame", "Live Casino")
                    }
                  >
                    <img src={CasinoTabsIcon} />
                    <span>Casino</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    className={activePage == "sports" ? "active" : ""}
                    onClick={() => {
                      !isAuth
                        ? handleModalShow()
                        : (window.location.href = "/sports/Inplay");
                    }}
                  >
                    <img src={sportsTabsIcon} />
                    <span>Sports</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="side-content">
              <Tabs defaultActiveKey="casino" id="uncontrolled-tab-example">
                <Tab
                  eventKey="casino"
                  title="Casino"
                  // title={
                  //   <span>
                  //     <img src={CasinoTabsIcon} />
                  //     Casino
                  //   </span>
                  // }
                >
                  <ul>
                    <li
                      className="liveCasino"
                      onClick={() =>
                        handleRedirection("/casinogame", "Live Casino")
                      }
                    >
                      <a>
                        <img src={livecasino} />
                        <span className="game-name">Live Casino</span>
                      </a>
                      {/* <Button
                        variant=""
                        className="before-sidebar-menu"
                        onClick={() => {
                          localStorage.setItem("isSideBarOpen", !showSideBar);
                          setShowSidebar(!showSideBar);
                        }}
                      >
                        <img src={MenuIcon} />
                      </Button> */}
                    </li>
                    <li
                      onClick={() =>
                        handleRedirection("/casinogame", "TV Games")
                      }
                    >
                      <a>
                        <img src={tvgame} />
                        <span className="game-name">TV Game</span>
                      </a>
                    </li>
                    <li
                      onClick={() =>
                        handleRedirection("/casinogame", "Casino Games")
                      }
                    >
                      <a>
                        <img src={casinogame} />
                        <span className="game-name"> Casino Game</span>
                      </a>
                    </li>
                    <li
                      onClick={() =>
                        handleRedirection("/casinogame", "Virtual Sports")
                      }
                    >
                      <a>
                        <img src={virtualgame} />
                        <span className="game-name"> Virtual Sports</span>
                      </a>
                    </li>
                    <li
                      onClick={() => handleRedirection("/casinogame", "Poker")}
                    >
                      <a>
                        <img src={poker} />
                        <span className="game-name">Poker</span>
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        !isAuth
                          ? handleModalShow()
                          : (window.location.href = "/casino/spribe/aviator");
                      }}
                    >
                      <a>
                        <img src={Aviator} />
                        <span className="game-name">Aviator</span>
                      </a>
                    </li>
                  </ul>

                  <Accordion className="sidebaraccordian">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Top Live Casino</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            {/* <a onClick={() => handleRedirection("/casino/ezugi")}> */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href = "/casino/ezugi");
                              }}
                            >
                              <img src={EzugiIcon} />
                              <span className="game-name">Ezugi</span>
                            </a>
                          </li>
                          <li>
                            {/* <a onClick={() => handleRedirection("/casino/evolution")} */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href =
                                      "/casino/evolution");
                              }}
                            >
                              <img src={EvolutionIcon} />
                              <span className="game-name"> Evolution</span>
                            </a>
                          </li>
                          <li>
                            {/* <a onClick={() => handleRedirection("/casino/vivo")}> */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href = "/casino/vivo");
                              }}
                            >
                              <img src={VivoIcon} />
                              <span className="game-name">Vivo</span>
                            </a>
                          </li>
                          <li>
                            {/* <a onClick={() => handleRedirection("/casino/pragmaticplay")} */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href =
                                      "/casino/pragmaticplay");
                              }}
                            >
                              <img src={PragamaticIcon} />
                              <span className="game-name">Pragamatic </span>
                            </a>
                          </li>
                          <li className="d-none">
                            {/* <a onClick={() => handleRedirection("/casino/onetouch")}> */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href = "/casino/onetouch");
                              }}
                            >
                              <img src={onetouchIcon} />
                              <span className="game-name">One Touch </span>
                            </a>
                          </li>
                          <li>
                            {/* <a onClick={() => handleRedirection("/casino/supernowa")}> */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href =
                                      "/casino/supernowa");
                              }}
                            >
                              <img src={SupernowaIcon} />
                              <span className="game-name">Supernowa</span>
                            </a>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion className="sidebaraccordian">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Top Categories</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            {/* <a onClick={() => handleRedirection("/slots")}> */}
                            <a
                              onClick={() => {
                                !isAuth
                                  ? handleModalShow()
                                  : (window.location.href = "/slots");
                              }}
                            >
                              <img src={EzugiIcon} />
                              <span className="game-name">Slots</span>
                            </a>
                          </li>
                          <li>
                            {/* <a onClick={() => handleRedirection("/casino/evolution")}> */}
                            <a
                              // onClick={() =>
                              //   handleRedirection("/slots", "Netent")
                              // }

                              onClick={() => {
                                !isAuth
                                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                  : handleRedirection("/slots", "Netent");
                              }}
                            >
                              <img src={EvolutionIcon} />
                              <span className="game-name"> Netend</span>
                            </a>
                          </li>
                          <li>
                            <a
                              // onClick={() =>
                              //   handleRedirection("/slots", "Red Tiger")
                              // }

                              onClick={() => {
                                !isAuth
                                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                  : handleRedirection("/slots", "Red Tiger");
                              }}
                            >
                              <img src={VivoIcon} />
                              <span className="game-name">Red Tiger</span>
                            </a>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <ul>
                    {/* <li>
                      <a href="#">
                        <img src={promotion} />
                        <span className="game-name">Promotion</span>
                      </a>
                    </li> */}
                    <li className="support">
                      <a href={`tel://${appDetails?.PHONE}`}>
                        <img src={contactUs} />
                        <span className="game-name">Support</span>
                      </a>
                    </li>
                  </ul>
                </Tab>

                <Tab
                  eventKey="sports"
                  title="Sports"
                  // title={
                  //   <span>
                  //     <img src={sportsTabsIcon} />
                  //     Sports
                  //   </span>
                  // }
                >
                  <ul>
                    <li>
                      <a href="#">
                        <img src={promotion} />
                        <span className="game-name">Promotion</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={contactUs} />
                        <span className="game-name">Contact Us</span>
                      </a>
                    </li>
                  </ul>
                </Tab>
              </Tabs>
            </div>
          </div>
        </ul>
      </div>
    </>
  );
};

export default BeforeLeftBar;
//
