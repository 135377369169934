import Slide1 from "../../../assets/images/homeSliders/TopGames/new/AndarBahar@4x-1@x2.jpg";
import Slide2 from "../../../assets/images/homeSliders/TopGames/new/AndarBaharOTT@4x@x2.jpg";
import Slide4 from "../../../assets/images/homeSliders/TopGames/new/Auto-Roulette@3x-min.jpg";
import Slide5 from "../../../assets/images/homeSliders/TopGames/new/AutoRoulette@4x@x2.jpg";
import Slide7 from "../../../assets/images/homeSliders/TopGames/new/Baccarat@3x-min.jpg";
import Slide9 from "../../../assets/images/homeSliders/TopGames/new/BetOnTeenPatti@4x-1@x2.jpg";
import Slide10 from "../../../assets/images/homeSliders/TopGames/new/BlackJackLive@3x-min-4.jpg";
import Slide11 from "../../../assets/images/homeSliders/TopGames/new/CashOrCash1@4x@x2.jpg";
import Slide14 from "../../../assets/images/homeSliders/TopGames/new/CrazyTime@5x@x2.jpg";
import Slide15 from "../../../assets/images/homeSliders/TopGames/new/CricketWarSL4@4x@x2.jpg";
import Slide17 from "../../../assets/images/homeSliders/TopGames/new/DoubleBallRoulette@4x@x2.jpg";
import Slide18 from "../../../assets/images/homeSliders/TopGames/new/DragonTiger@4x@x2.jpg";
import Slide19 from "../../../assets/images/homeSliders/TopGames/new/Dreamcatcher@3x-min.jpg";
import Slide20 from "../../../assets/images/homeSliders/TopGames/new/FirstPersonAmericanRoulette1@513x767@x2.jpg";
import Slide22 from "../../../assets/images/homeSliders/TopGames/new/FortuneBaccarat@4x@x2.jpg";
import Slide24 from "../../../assets/images/homeSliders/TopGames/new/InfiniteBlackjack@3x-min.jpg";
import Slide25 from "../../../assets/images/homeSliders/TopGames/new/InstantRoulette1@3x.jpg";
import Slide27 from "../../../assets/images/homeSliders/TopGames/new/LightningBlackjack@4x-1@x2.jpg";
import Slide29 from "../../../assets/images/homeSliders/TopGames/new/OneDayTeenPatti1@4x@x2.jpg";
import Slide31 from "../../../assets/images/homeSliders/TopGames/new/PowerBJ@3x-min.jpg";
import Slide32 from "../../../assets/images/homeSliders/TopGames/new/Roulette3@3x-min.jpg";
import Slide35 from "../../../assets/images/homeSliders/TopGames/new/SalonPriveBlackjackSL3@4x@x2.jpg";
import Slide36 from "../../../assets/images/homeSliders/TopGames/new/SicBoEzugiSL1@4x@x2.jpg";
import Slide37 from "../../../assets/images/homeSliders/TopGames/new/SpeedRoulette@4x-1@x2.jpg";
import Slide42 from "../../../assets/images/homeSliders/TopGames/new/TexasHoldemBonus@4x@x2.jpg";
import Slide43 from "../../../assets/images/homeSliders/TopGames/new/TripleCardPoker@4x@x2.jpg";
import Slide45 from "../../../assets/images/homeSliders/TopGames/new/UltimateSicBo@4x@x2.jpg";
import Slide46 from "../../../assets/images/homeSliders/TopGames/new/UltimateTexasHoldem@4x@x2.jpg";
import SlideAviator from "../../../assets/images/homeSliders/TopGames/new/Aviator-img.jpg";

const TopGamesData = [
  {
    a: "PLAY",
    link: "/casino/spribe/aviator",
    image: SlideAviator,
    alt: "aviator",
    gameName: "Aviator",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-andar-bahar",
    image: Slide1,
    alt: "casino game",
    gameName: "Andar Bahar",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide3,
  //   alt: "casino game",
  //   gameName: "Atom Roulette",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-auto-roulette",
    image: Slide4,
    alt: "casino game",
    gameName: "Auto-Roulette",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-speed-auto-roulette",
    image: Slide5,
    alt: "casino game",
    gameName: "Speed Auto Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide6,
  //   alt: "casino game",
  //   gameName: "Bac Bo",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-baccarat",
    image: Slide7,
    alt: "casino game",
    gameName: "Baccarat",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-casino-marina-andar-bahar",
    image: Slide2,
    alt: "casino game",
    gameName: "Andar Bahar OTT",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide8,
  //   alt: "casino game",
  //   gameName: "Baccarat Queenco",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-bet-on-teen-patti",
    image: Slide9,
    alt: "casino game",
    gameName: "Bet On Teen Patti",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-blackjack-b",
    image: Slide10,
    alt: "casino game",
    gameName: "Black Jack Live",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-cash-or-crash",
    image: Slide11,
    alt: "casino game",
    gameName: "Cash Or Cash",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/evolution/CasinoHoldem",
  //   image: Slide12,
  //   alt: "casino game",
  //   gameName: "Casino Holdem",
  // },

  {
    a: "PLAY",
    link: "/casino/evolution/CrazyTime",
    image: Slide14,
    alt: "casino game",
    gameName: "Crazy Time",
  },
  {
    a: "PLAY",
    link: "/casino/ezugi/CricketWar",
    image: Slide15,
    alt: "casino game",
    gameName: "Cricket War ",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide16,
  //   alt: "casino game",
  //   gameName: "Dead Or Alive Saloon",
  // },
  {
    a: "PLAY",
    link: "/casino/evolution/DoubleBallRoulette",
    image: Slide17,
    alt: "casino game",
    gameName: "Double Ball Roulette",
  },
  {
    a: "PLAY",
    link: "/casino/ezugi/DragonTiger1",
    image: Slide18,
    alt: "casino game",
    gameName: "Dragon Tiger",
  },
  {
    a: "PLAY",
    link: "/casino/evolution/DreamCatcher",
    image: Slide19,
    alt: "casino game",
    gameName: "Dreamcatcher",
  },
  {
    a: "PLAY",
    link: "/casino/evolution/AmericanRoulette",
    image: Slide20,
    alt: "casino game",
    gameName: "First Person American Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/evolution/FootballStudio",
  //   image: Slide21,
  //   alt: "casino game",
  //   gameName: "Football Studio Roulette",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-fortune-baccarat",
    image: Slide22,
    alt: "casino game",
    gameName: "Fortune Baccarat",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/evolution/GonzoTreasureHunt",
  //   image: Slide23,
  //   alt: "casino game",
  //   gameName: "Gonzos Treasure Hunt",
  // },
  {
    a: "PLAY",
    link: "/casino/evolution/InfiniteBlackjack",
    image: Slide24,
    alt: "casino game",
    gameName: "Infinite Blackjack",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-instant-roulette",
    image: Slide25,
    alt: "casino game",
    gameName: "Instant Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide26,
  //   alt: "casino game",
  //   gameName: "Keno",
  // },
  {
    a: "PLAY",
    link: "/casino/ezgevo-lightning-blackjack",
    image: Slide27,
    alt: "casino game",
    gameName: "Lightning Blackjack",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide28,
  //   alt: "casino game",
  //   gameName: "Monopoly Live",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-one-day-teen-patti",
    image: Slide29,
    alt: "casino game",
    gameName: "One Day Teen Patti",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide30,
  //   alt: "casino game",
  //   gameName: "Peek Baccarat",
  // },
  {
    a: "PLAY",
    link: "/casino/evolution/PowerBlackjack",
    image: Slide31,
    alt: "casino game",
    gameName: "Power BJ",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-speed-roulette",
    image: Slide32,
    alt: "casino game",
    gameName: "Speed Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide33,
  //   alt: "casino game",
  //   gameName: "Roulette Live",
  // },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide34,
  //   alt: "casino game",
  //   gameName: "Roulette Live",
  // },
  {
    a: "PLAY",
    link: "/casino/ezgevo-salon-priv-baccarat-a",
    image: Slide35,
    alt: "casino game",
    gameName: "Salon Prive Blackjack SL",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-super-sic-bo",
    image: Slide36,
    alt: "casino game",
    gameName: "SicBo Ezugi",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-speed-roulette",
    image: Slide37,
    alt: "casino game",
    gameName: "Speed Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide38,
  //   alt: "casino game",
  //   gameName: "Speed Roulette",
  // },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide39,
  //   alt: "casino game",
  //   gameName: "Super Andar Bahar",
  // },
  // {
  //   a: "PLAY",
  //   link: "/casino/ezugi/TeenPatti02",
  //   image: Slide40,
  //   alt: "casino game",
  //   gameName: "Teen Patti",
  // },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide41,
  //   alt: "casino game",
  //   gameName: "Teen Patti",
  // },
  {
    a: "PLAY",
    link: "/casino/evolution/Texasholdem",
    image: Slide42,
    alt: "casino game",
    gameName: "Texas Holdem Bonus",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-three-card-poker",
    image: Slide43,
    alt: "casino game",
    gameName: "Three Card Poker",
  },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide44,
  //   alt: "casino game",
  //   gameName: "Ultimate Roulette",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-ultimate-sic-bo",
    image: Slide45,
    alt: "casino game",
    gameName: "Ultimate SicBo",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-ultimate-texas-holdem",
    image: Slide46,
    alt: "casino game",
    gameName: "Ultimate Texas Holdem",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/ezugi/UnlimitedBlackjack",
  //   image: Slide47,
  //   alt: "casino game",
  //   gameName: "Unlimited Blackjack",
  // },
  // {
  //   a: "PLAY",
  //   link: "/casino/ezugi/FortuneBaccart",
  //   image: Slide48,
  //   alt: "casino game",
  //   gameName: "VIP Fortune Baccarat",
  // },
  // {
  //   a: "PLAY",
  //   link: "#",
  //   image: Slide49,
  //   alt: "casino game",
  //   gameName: "Xxxtreme Lightning Roulette",
  // },
  // ... more slide objects
];

export default TopGamesData;
