import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Tab, Tabs } from "react-bootstrap";
import CasinoGames from "./CasinoGames";
import TopGamesData from "./homeSliders/TopGamesData";
import NewGamesData from "./homeSliders/NewGamesData";
import { ProviderData } from "./homeSliders/ProviderData";
import { HoldemData, lottery, sicboData } from "./homeSliders/HoldemData";
import SliderSection from "./sliderSection";
import { Slide } from "react-toastify";
import Slider from "react-slick";

import TopGamesIcon from "../../assets/images/icons/topgameicon.png";
import NewGamesIcon from "../../assets/images/icons/newgame.png";
import VirtualSports from "../../assets/images/icons/virtualsports.png";
import Recomment from "../../assets/images/icons/recommeny.png";
import providerIcon from "../../assets/images/icons/provider.png";
import bonusIcon from "../../assets/images/icons/bonus.png";
import VirtualData from "./homeSliders/VirtualData";
import BonusData from "./homeSliders/BonusData";
import RecommendedData from "./homeSliders/RecommendedData";

import PlayBtn from "../../assets/images/icons/play.png";

import { APP_CONST } from "../../config/const";
import ModalWrapper from "../../containers/ModalWrapper";

const settings = {
  dots: false,
  arrows: true,
  autoplay: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 7,
  slidesToScroll: 7,
  responsive: [
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const settings1 = {
  dots: false,
  arrows: true,
  autoplay: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const settings2 = {
  dots: false,
  arrows: true,
  autoplay: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const HomeTabsSection = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);

  const handleModalShow = (modalName = APP_CONST.AUTH_MODAL.LOGIN) => {
    setShowAuthModal(true);
    setDefaultModal(modalName);
  };

  const { isAuth } = useSelector((state) => state.auth);

  return (
    <div className="home_tabs_section casino_games">
      <div className="mb-5" id="topgames">
        <h4>
          <img src={TopGamesIcon} />
          Top Games
        </h4>
        <Slider {...settings}>
          {TopGamesData.map((slide) => (
            <div key={slide.a}>
              <div className="game-box">
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5" id="newgames">
        <h4>
          <img src={NewGamesIcon} />
          New Games
        </h4>
        <Slider {...settings1}>
          {NewGamesData.map((slide) => (
            <div key={slide.a}>
              <div className="game-box">
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5" id="virtualgames">
        <h4>
          <img src={VirtualSports} />
          Virtual Sports
        </h4>
        <Slider {...settings1}>
          {VirtualData.map((slide) => (
            <div key={slide.a}>
              <div className="game-box">
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5 providersection" id="providers">
        <h4>
          <img src={providerIcon} />
          Provider
        </h4>
        <Slider {...settings1}>
          {ProviderData.map((slide) => (
            <div key={slide.a}>
              <div className="providersection">
                <div className="game-box topProviders">
                  <div className="img-box">
                    <div className="overlay">
                      <a
                        onClick={() => {
                          !isAuth
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : (window.location.href = slide.link);
                        }}
                        className="play-btn"
                      >
                        <img src={PlayBtn} />
                      </a>
                    </div>
                    <img src={slide.image} alt="{slide.alt}" />
                  </div>
                  <div className="game_name">{slide.gameName}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5 bonussection" id="bonuses">
        <h4>
          <img src={bonusIcon} />
          Bonuses
        </h4>
        <Slider {...settings2}>
          {BonusData.map((slide) => (
            <div key={slide.a}>
              <div className="game-box">
                <div className="img-box">
                  <div className="overlay d-none">
                    {/* <a href={slide.link} className="play-btn"> */}
                    <a href="#" className="play-btn">
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5" id="recommended">
        <h4>
          <img src={Recomment} />
          Recommended
        </h4>
        <Slider {...settings}>
          {RecommendedData.map((slide) => (
            <div key={slide.a}>
              <div className="game-box">
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {showAuthModal && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModal}
        />
      )}
    </div>
  );
};

export default HomeTabsSection;
