import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { slotsData } from "../../lib/data/slots.data";
import PlayBtn from "../../assets/images/icons/play.png";
import VirtualData from "./homeSliders/VirtualData";
import BonusData from "./homeSliders/BonusData";
import RecommendedData from "./homeSliders/RecommendedData";
import TopGamesData from "./homeSliders/TopGamesData";
import NewGamesData from "./homeSliders/NewGamesData";

const SearchModal = ({ show, setShow }) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    return () => {
      setFilteredData([]);
    };
  }, []);

  const handleSearch = (e) => {
    const value = e?.target?.value?.toLowerCase();

    if (value) {
      let filteredValues = [];

      Object.values(slotsData).forEach((slide) => {
        if (slide.name !== "All Games") {
          Object.values(slide).map((item, index) => {
            if (typeof item !== "string") {
              let response = Object.values(item).filter(
                (f) =>
                  f?.name
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(value.toLowerCase().trim()) > -1
              );
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            }
          });
        }
      });

      filteredValues = [
        ...filteredValues,
        ...parseDataToSameFormat(searchHomePageGames(VirtualData, value)),
      ];
      filteredValues = [
        ...filteredValues,
        ...parseDataToSameFormat(searchHomePageGames(BonusData, value)),
      ];
      filteredValues = [
        ...filteredValues,
        ...parseDataToSameFormat(searchHomePageGames(RecommendedData, value)),
      ];
      filteredValues = [
        ...filteredValues,
        ...parseDataToSameFormat(searchHomePageGames(TopGamesData, value)),
      ];

      filteredValues = [
        ...filteredValues,
        ...parseDataToSameFormat(searchHomePageGames(NewGamesData, value)),
      ];

      setFilteredData(filteredValues);
    }
  };

  const searchHomePageGames = (gameArray = [], valueToSearch = "") => {
    if (gameArray.length) {
      return gameArray.filter(
        (f) =>
          f?.gameName?.toLowerCase()?.indexOf(valueToSearch?.toLowerCase()) > -1
      );
    }
  };

  const parseDataToSameFormat = (dataToParse = []) => {
    if (dataToParse.length == 0) {
      return [];
    }
    return dataToParse.map((data) => {
      return {
        redirectUrl: data?.link,
        imgUrl: data?.image,
        alt: data?.alt,
        name: data?.gameName,
      };
    });
  };

  return (
    <>
      <Modal className="searchGamesModal" show={show}>
        <Modal.Header
          closeButton
          onClick={() => {
            setShow(false);
            setFilteredData([]);
          }}
        >
          <Modal.Title>Search For Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control onChange={handleSearch} placeholder="Search games" />
          <div className="PageContainer">
            <div className="slots-section">
              <div className="games-section casino_games">
                {(filteredData.length &&
                  filteredData.map((s) => {
                    return (
                      <div className="game-box">
                        <div className="img-box">
                          <div className="overlay">
                            <a
                              href={s?.redirectUrl || s?.link}
                              className="play-btn"
                            >
                              <img src={PlayBtn} />
                            </a>
                          </div>
                          <img src={s.imgUrl || s?.image} alt="{slide.alt}" />
                        </div>
                        <div className="game_name">{s.name}</div>
                        {/* <div className="game_name">{s.providerName}</div> */}
                      </div>
                    );
                  })) ||
                  null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-none">
          <Button onClick={() => setShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SearchModal;
