import Slide1 from "../../../assets/images/homeSliders/VirtualSlider/Cricket.jpg";
import Slide2 from "../../../assets/images/homeSliders/VirtualSlider/In-Play.jpg";
import Slide3 from "../../../assets/images/homeSliders/VirtualSlider/Soccer.jpg";
import Slide5 from "../../../assets/images/homeSliders/VirtualSlider/Tennis.jpg";

const VirtualData = [
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide1,
    alt: "casino game",
    gameName: "Diamond Roulette",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide2,
    alt: "casino game",
    gameName: "Auto Roulette",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide3,
    alt: "casino game",
    gameName: "Double Ball Roulette",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide5,
    alt: "casino game",
    gameName: "Instant Roulette1",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide1,
    alt: "casino game",
    gameName: "Diamond Roulette",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide2,
    alt: "casino game",
    gameName: "Auto Roulette",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide3,
    alt: "casino game",
    gameName: "Double Ball Roulette",
  },
  {
    a: "PLAY",
    link: "/sports/Inplay",
    image: Slide5,
    alt: "casino game",
    gameName: "Instant Roulette1",
  },
  // ... more slide objects
];

export default VirtualData;
