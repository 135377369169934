import React, { useState } from "react";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import Logo from "../assets/images/logo.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { Col, Container, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/spribe.png";
import ProviderLogo5 from "../assets/images/providers/netent.png";
import ProviderLogo6 from "../assets/images/providers/redtiger.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";

import SportsIcon from "../assets/images/icons/sportsbook.svg";
import CasinoIcon from "../assets/images/icons/casino.svg";
import HomeIcon from "../assets/images/icons/home.svg";
import EzugiIcon from "../assets/images/icons/ezugi.svg";
import DepositIcon from "../assets/images/icons/deposit.svg";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <footer className="footer">
        <Container className="max-1200w d-none">
          <div className="providers_sec ">
            <Row>
              <Col md={6} xs={12}>
                <div className="provider-mob-border">
                  <div className="heading mb-4">
                    <div className="text">
                      <h5>Top Providers</h5>
                      <p>
                        Play Your favorite Casino Games with{" "}
                        <span>Top Providers</span>
                      </p>
                    </div>
                  </div>

                  <div className="providers_logo d-flex flex-wrap justify-content-center">
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo2} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo3} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo4} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo5} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo6} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={ProviderLogo7} alt="Provider Logo" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="">
                  <div className="heading payment-heading mb-4 ">
                    <div className="text">
                      <h5>Payment Gateway</h5>
                      <p>
                        Accept Process &amp; disburse{" "}
                        <span>Digital Payments </span>
                        for your bussiness
                      </p>
                    </div>
                  </div>

                  <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo1} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box payment-height">
                      <img src={PaymentLogo2} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo3} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo4} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box payment-height">
                      <img src={PaymentLogo5} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box">
                      <img src={PaymentLogo6} alt="Provider Logo" />
                    </a>
                    <a href="#" className="logo_box payment-height">
                      <img src={PaymentLogo7} alt="Provider Logo" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="">
            <div className="providers_img">
              <a href="#">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="#">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="#">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="#">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>

            <div className="footer-links">
              <ul>
                <li>
                  <a href="/contactuss">Contact Us</a>
                </li>
                <li>
                  <a href="/privacypolicys ">Privacy Policy</a>
                </li>
                <li>
                  <a href="/responsiblegames">Responsible Gaming</a>
                </li>
                <li>
                  <a href="/fairplays">Fair Play</a>
                </li>
                <li>
                  <a href="/gamesrules">Games Rules</a>
                </li>
                <li>
                  <a href="/termss">Terms and Conditions</a>
                </li>
              </ul>
            </div>

            <div className="helpline d-none">
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                WhatsApp Us
              </a>
              {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
            </div>

            <div className="d-flex justify-content-center mt-2 d-none">
              <img
                src={PaymentMod}
                loading="lazy"
                style={{ width: "250px" }}
                alt="Payment Otions"
              />
            </div>

            <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <img
                src={Betfair}
                loading="lazy"
                className="mb-3  mx-3 betfair"
                alt="Betfair Logo"
              />
              <a href="#">
                <img
                  src={Gamble18}
                  loading="lazy"
                  alt="Gamble Responsible"
                  className="mb-3 betfair"
                />
              </a>
            </div>

            <p>{appDetails?.FOOTER_TEXT || ""}</p>
            <div className="paymentImages d-none">
              <ul>
                <li>
                  <img src="./images/sewa.jpeg" />
                </li>
                <li>
                  <img src="./images/khalti.jpeg" />
                </li>
              </ul>
            </div>

            <div className="social-icons d-none">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? appDetails?.WHATS_APP_URL
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {" "}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                    {/* <span className="notification-count">2</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>

        <Container className="max-1200w">
          <Row>
            <Col md={3} xs={6} className="firstfooterlinks">
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Casino</h2>
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        Casino
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        TV Games
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        Live Casino
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        Casino Games
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>SiteMap</h2>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    {/* <li>
                      <a href="#">About Us</a>
                    </li> */}
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        Deposit
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        Withdraw Funds
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Policies</h2>
                  <ul>
                    <li>
                      <a href="/privacypolicys ">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/responsiblegames">Responsible Gaming</a>
                    </li>
                    <li>
                      <a href="/fairplays">Fair Play</a>
                    </li>
                    <li>
                      <a href="/gamesrules">Games Rules</a>
                    </li>
                    <li>
                      <a href="/termss">Terms and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Support</h2>
                  <ul>
                    <li>
                      <a href="/contactuss">Contact Us</a>
                    </li>
                    {/* <li>
                      <a href="#">FAQ</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <div className="cryptoCurrencyWrapper">
            <a href="#" className="logo_box">
              <img src={PaymentLogo1} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box payment-height">
              <img src={PaymentLogo2} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PaymentLogo3} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PaymentLogo4} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box payment-height">
              <img src={PaymentLogo5} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box">
              <img src={PaymentLogo6} alt="Provider Logo" />
            </a>
            <a href="#" className="logo_box payment-height">
              <img src={PaymentLogo7} alt="Provider Logo" />
            </a>
          </div>

          <div className="providers_img">
            <a href="#">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="#">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="#">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="#">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-logo">
            <div className="logo">
              <a href="/" style={{ width: "180px", display: "block" }}>
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              </a>
            </div>

            <div className="copyright">
              © 2024 flybets.in | All Rights Reserved.
            </div>

            <div className="social-icons">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? appDetails?.WHATS_APP_URL
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {" "}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                    {/* <span className="notification-count">2</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div>

            <p>{appDetails?.FOOTER_TEXT || ""}</p>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-md-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}
                >
                  {/* <SportsIcon /> */}
                  <img src={SportsIcon} alt="sports" />
                  <span>Sports</span>
                </a>
              </li>
              <li>
                <a href="/casinogame">
                  {/* <GamesIcon /> */}
                  <img src={CasinoIcon} alt="casino" />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a className="active" href="/">
                  {/* <HomeIcon /> */}
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}
                >
                  {/* <GamesIcon /> */}
                  <img src={EzugiIcon} alt="ezugi" />
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}
                >
                  {/* <CashierIcon /> */}
                  <img src={DepositIcon} alt="deposit" />
                  <span>Deposit</span>
                </a>
              </li>
            </ul>
          </div>
        </Container>

        <LoginModal
          show={showAuthModals.login}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
          handleModalShow={handleModalShow}
        />
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
        <RegisterModal
          show={showAuthModals.register}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
          handleModalShow={handleModalShow}
        />
      </footer>
    </>
  );
};

export default BeforeLoginFooter;
