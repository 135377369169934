import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { slotssData } from "../../../../lib/data/slotss.data";
import MainSlider from "../../../../containers/MainSlider";
import { getBanners } from "../../../../redux/app/actions";
import Search from "../../../../assets/svg/SearchIcon";
import SliderSection from "../../../components/sliderSection";
import { Form } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";

const Slots = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const location = useLocation();
  const container = useRef();
  const tl = useRef();
  const { banners } = useSelector((state) => state.app);
  const [searchedText, setSearchText] = useState("");

  const [showSlider, setShowSlider] = useState({
    show: true,
    category: "Overview",
    showMultipleSlides: {
      "Popular Games": true,
      "New Games": true,
      Megaways: true,
      Netent: true,
      "Red Tiger": true,
      // "One Touch": true,
      "One Play": true,
      // "TV Games": true,
      // Supernowa: true,
      "Pragmatic Play": true,
      "All Games": true,
    },
  });
  const [sliderPageNumber, setSliderPageNumber] = useState({
    "Popular Games": 1,
    "New Games": 1,
    Megaways: 1,
    Netent: 1,
    "Red Tiger": 1,
    // "One Touch": 1,
    "One Play": 1,
    // Supernowa: 1,
    // "TV Games": 1,
    "Pragmatic Play": 1,
    "All Games": 1,
  });

  const [activeCategory, setActiveCategory] = useState("Overview");
  useEffect(() => {
    if (location?.state?.tabName) {
      setActiveCategory(location?.state?.tabName);
    }
  }, [location]);
  const categoriesTab = [
    "Overview",
    "Popular Games",
    "New Games",
    "Megaways",
    "Netent",
    "Red Tiger",
    // "One Touch",
    "One Play",
    // "Supernowa",
    // "TV Games",
    // "Pragmatic Play",
    "All Games",
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanners());
  }, []);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const handleSearch = (e) => {
    setSearchText(e?.target?.value);
    const value = e?.target?.value?.toLowerCase();
    if (value) {
      setIsSearched(true);
      let filteredValues = [];

      Object.values(slotssData).forEach((slide) => {
        if (slide.name !== "All Games") {
          Object.values(slide).map((item, index) => {
            if (typeof item !== "string") {
              let response = Object.values(item).filter(
                (f) =>
                  f?.name
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(value.toLowerCase().trim()) > -1
              );
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            }
          });
        }
      });

      setFilteredData(filteredValues);
    } else {
      setIsSearched(false);
    }
  };
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const boxes = self.selector(".dot");
      tl.current = gsap
        .timeline()
        .to(boxes[0], { x: 260 })

        .reverse();
    }, container); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  const toggleTimeline = () => {
    tl.current.reversed(!tl.current.reversed());
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main Beforehomepage">
        <div className="max-1200w">
          <div className="GamesSection SlotsSectionNew">
            <div className="mainBanner">
              <MainSlider banners={banners} />
            </div>

            <div className="innerSerachbar my-3">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>

            <div className="containerCss">
              <div className="casino-category filters">
                <ul>
                  {categoriesTab.map((tab, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setShowSlider((p) => ({
                          category: tab,
                          show: true,
                          ...p,
                        }));
                        setActiveCategory(tab);
                      }}
                    >
                      <a
                        href="javascript:void(0)"
                        style={
                          activeCategory === tab
                            ? {
                                color: "#fff",
                                background: "#2f4553",
                              }
                            : {}
                        }
                      >
                        {tab}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <SliderSection
              isSearched={isSearched}
              activeCategory={activeCategory}
              data={slotssData}
              sliderPageNumber={sliderPageNumber}
              isAuth={isAuth}
              showSlider={showSlider}
              filteredData={filteredData}
              setSliderPageNumber={setSliderPageNumber}
              setShowSlider={setShowSlider}
            />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Slots;
