import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  mobileVerification,
  registerUser,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import { Toaster } from "../../../utils/toaster";
import { APP_CONST } from "../../../config/const";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";

const RegisterModal = ({
  show,
  handleClose,
  handleModalShow,
  agentCode: agentCodeProp,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agentCode, setAgentCode] = useState(agentCodeProp);
  const { appDetails } = useSelector((state) => state.app);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpValue, setOtpValue] = useState(null);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword, email } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (appDetails?.IS_EMAIL) {
      if (!email || email === "") {
        newErrors.email = "Email cannot be empty";
      }
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password != confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async () => {
    let errorsData = validateData();
    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      const phoneNumber = parsePhoneNumber("+" + mobileNumber);
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: phoneNumber?.nationalNumber,
            country_code: phoneNumber?.countryCallingCode,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };

  // Send OTP to mobile
  const handleSendOtp = async () => {
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    const mobileOtpRes = await sendOtpToMobile({
      mobile: phoneNumber?.nationalNumber,
      country_code: phoneNumber?.countryCallingCode,
    });
    if (mobileOtpRes && mobileOtpRes?.data?.error === false) {
      setIsOtpSent(true);
      Toaster(
        APP_CONST.TOAST_TYPE.SUCCESS,
        mobileOtpRes?.data?.message || "OTP Sent"
      );
    }
  };

  // Verify OTP
  const handleOtpVerify = async () => {
    if (otpValue) {
      const phoneNumber = parsePhoneNumber("+" + mobileNumber);
      const otpVerificationResponse = await mobileVerification({
        mobile: phoneNumber?.nationalNumber,
        country_code: phoneNumber?.countryCallingCode,
        otp: otpValue,
      });

      if (
        otpVerificationResponse &&
        otpVerificationResponse?.data?.error === false
      ) {
        setIsOtpVerified(true);
        Toaster(
          APP_CONST.TOAST_TYPE.SUCCESS,
          otpVerificationResponse?.data?.message || "OTP verified"
        );
      }
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
      centered
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>Create an account!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="Login-rightPart">
            <div className="form-steps">
              <Form>
                <Form.Group className="form-group" controlId="formBasicName">
                  <Form.Label>
                    Full name <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Full name"
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {(appDetails?.IS_EMAIL && (
                  <Form.Group className="form-group" controlId="formBasicName">
                    <Form.Label>
                      Email <small>*</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                )) ||
                  null}
                <Form.Group className="form-group" controlId="formBasicName">
                  <Form.Label>
                    Username <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Username"
                    autoComplete="off"
                    onChange={handleChange}
                    isInvalid={!!errors.userName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.userName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    Password <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    {showPassword ? <IoEyeOffSharp /> : <IoEyeSharp />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    Confirm password <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowConfirmPassword((p) => !p)}
                  >
                    {showConfirmPassword ? <IoEyeOffSharp /> : <IoEyeSharp />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                {
                  <Form.Group
                    className="form-group"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Agent code</Form.Label>
                    <Form.Control
                      type={"text"}
                      name="agent_code"
                      placeholder="Please enter agent code"
                      value={agentCode}
                      onChange={(e) => setAgentCode(e.target.value)}
                    />
                  </Form.Group>
                }
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    Phone <small>*</small>
                  </Form.Label>
                  <PhoneInput
                    country={"in"}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    countryCodeEditable={false}
                    onlyCountries={["in", "np"]}
                    inputStyle={{
                      padding: "0.7rem 0.75rem",
                      paddingLeft: "50px",
                    }}
                    onChange={(phone) => {
                      setMobileNumber(phone);
                    }}
                  />
                  <Button
                    className="otp-btn send"
                    onClick={handleSendOtp}
                    disabled={!mobileNumber}
                  >
                    Send Otp
                  </Button>
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    Enter OTP <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                  <Button
                    className="otp-btn verify"
                    onClick={handleOtpVerify}
                    disabled={!isOtpSent || !otpValue}
                  >
                    Verify Otp
                  </Button>
                </Form.Group>

                <Button
                  variant="primary"
                  // type="submit"
                  onClick={handleSubmit}
                  disabled={!isOtpVerified}
                >
                  SUBMIT
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;
