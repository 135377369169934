import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Lobby from "../../../../assets/images/icons/casino/Lobby.png";
import LiveCasino from "../../../../assets/images/icons/casino/LiveCasino.png";
import CasinoGames from "../../../../assets/images/icons/casino/CasinoGames.png";
import VirtualSports from "../../../../assets/images/icons/casino/VirtualSports.png";
import TVGames from "../../../../assets/images/icons/casino/TvGame.png";
import Slider from "react-slick";
import { slotsData } from "../../../../lib/data/slots.data";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PlayBtn from "../../../../assets/images/icons/play.png";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

import { APP_CONST } from "../../../../config/const";
import LoginModal from "../../../../views/components/authModals/LoginModal";
import MobileNumberModal from "../../../../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../../../../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../../../../views/components/authModals/RegisterModal";
import ModalWrapper from "../../../../containers/ModalWrapper";

const getImageUrl = (url) => {
  if (url) {
    return url.slice(1, url.length) || url;
  }
  return "";
};
const GameType = ({ isAuth }) => {
  const { gameType } = useParams();
  const [activeCategory, setActiveCategory] = useState("Lobby");
  const [activeGame, setActiveGame] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleModalShow = (modalName) => {
    setShowAuthModal(true);
    setDefaultModal(modalName);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      let filteredValues = [];
      Object.values(slotsData)
        .filter((f) => f?.name === activeCategory || activeCategory === "Lobby")
        .map((slots) => {
          Object.values(slots)
            .filter((f) => typeof f !== "string")
            .map((slide, index) => {
              let response = Object.values(slide).filter((f) => {
                return (
                  f?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                );
              });
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            });
        });
      setFilteredData(filteredValues);
    } else {
      setFilteredData([]);
    }
  };
  const categoriesTab = [
    // { tabname: "Lobby", tabImg: Lobby },
    { tabname: "Live Casino", tabImg: LiveCasino },
    { tabname: "Casino Games", tabImg: CasinoGames },
    // { tabname: "TV Games", tabImg: TVGames },
    // { tabname: "Virtual Sports", tabImg: VirtualSports },
    // { tabname: "Poker", tabImg: Poker },
  ];

  const gameTypes = [
    { id: 1, name: "All", key: "all" },
    { id: 2, name: "Black Jack", key: "blackjack" },
    { id: 3, name: "Baccarat", key: "baccarat" },
    // { id: 4, name: "Card Game", key: "cardgame" },
    { id: 5, name: "Roulette", key: "roulette" },
    { id: 6, name: "Sic Bo", key: "sicbo" },
    // { id: 7, name: "Lobby", key: "lobby" },
    { id: 8, name: "Poker", key: "poker" },
  ];
  useEffect(() => {
    if (gameType) {
      const category = categoriesTab.find(
        (c) => c.tabname === gameType.replace("-", " ")
      );
      if (category) {
        setActiveCategory(category.tabname);
      }
    }
  }, [gameType]);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main Beforehomepage moreCasinoOptions">
        <div className="PageContainer ">
          <div className="casinogamepageSection">
            <div className="casino-category filters">
              <div className=""></div>
              <ul>
                {categoriesTab.map((tab, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      navigate(
                        `/casinogame/${tab?.tabname?.replace(" ", "-")}`
                      );
                    }}
                  >
                    <a
                      href="#"
                      style={
                        activeCategory === tab?.tabname
                          ? {
                              color: "#fff",
                              background: "#2f4553",
                            }
                          : {}
                      }
                    >
                      {/* {<img src={Lobby} />} */}

                      {/* {imgTabs} */}
                      <img src={tab?.tabImg} />
                      {tab?.tabname}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="slots-section">
              <div className="games-section casino_games">
                {Object.values(slotsData)
                  .filter(
                    (f) =>
                      f?.name === activeCategory || activeCategory === "Lobby"
                  )
                  .map((slots) => (
                    <Slider
                      // settings={settings}
                      slidesToShow={7}
                      slidesToScroll={7}
                      infinite={true}
                      responsive={[
                        {
                          breakpoint: 1399,
                          settings: {
                            slidesToShow: 7,
                            slidesToScroll: 7,
                          },
                        },
                        {
                          breakpoint: 1199,
                          settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5,
                          },
                        },
                        {
                          breakpoint: 991,
                          settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                          },
                        },
                        {
                          breakpoint: 767,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                          },
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          },
                        },
                      ]}
                    >
                      {Object.values(slots)
                        .filter((f) => typeof f !== "string")
                        .map((slide) =>
                          Object.values(slide)
                            .filter(
                              (item) => item?.providerName !== "Evolution"
                            )
                            .map((s, index) => (
                              <div className="game-box" key={index}>
                                <div className="img-box">
                                  <div className="overlay">
                                    <a
                                      onClick={() => {
                                        !isAuth
                                          ? handleModalShow(
                                              APP_CONST.AUTH_MODAL.LOGIN
                                            )
                                          : (window.location.href =
                                              s.redirectUrl);
                                      }}
                                      className="play-btn"
                                    >
                                      <img src={PlayBtn} />
                                    </a>
                                  </div>
                                  <img
                                    style={{ width: "10%" }}
                                    src={getImageUrl(s?.imgUrl)}
                                    alt={slide?.alt}
                                  />
                                </div>
                                <div className="game_name">{s?.name}</div>
                              </div>
                            ))
                        )}
                    </Slider>
                  ))}
              </div>
            </div>

            <div className="innerSerachbar">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>

            <div className="slots-section">
              <div
                className="casino-category filters"
                style={{ display: "flex" }}
              >
                <ul>
                  {gameTypes.map((game, idx) => (
                    <li className="active">
                      <a
                        href="#"
                        key={idx}
                        onClick={() => setActiveGame(game.key)}
                        style={
                          activeGame === game.key
                            ? { backgroundColor: "#2f4553" }
                            : {}
                        }
                      >
                        {game?.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="games-section casino_games">
                <div className="Casino Games">
                  <ul>
                    <li>
                      {filteredData.length == 0
                        ? Object.values(slotsData)
                            .filter(
                              (f) =>
                                f?.name === activeCategory ||
                                activeCategory === "Lobby"
                            )
                            .map((slots) =>
                              Object.values(slots)
                                .filter((f) => typeof f !== "string")
                                .map((slide) =>
                                  Object.values(slide)
                                    .filter(
                                      (f) =>
                                        f?.gameType === activeGame ||
                                        activeGame === "all"
                                    )
                                    .filter(
                                      (item) =>
                                        item?.providerName !== "Evolution"
                                    )
                                    .map((s, index) => (
                                      <div className="game-box" key={index}>
                                        <div className="img-box">
                                          <div className="overlay">
                                            <a
                                              onClick={() => {
                                                !isAuth
                                                  ? handleModalShow(
                                                      APP_CONST.AUTH_MODAL.LOGIN
                                                    )
                                                  : (window.location.href =
                                                      s.redirectUrl);
                                              }}
                                              className="play-btn"
                                            >
                                              <img src={PlayBtn} />
                                            </a>
                                          </div>
                                          <img
                                            style={{ width: "10%" }}
                                            src={getImageUrl(s?.imgUrl)}
                                            alt={s?.alt}
                                          />
                                        </div>
                                        <div className="game_name">
                                          {s?.name}
                                        </div>
                                      </div>
                                    ))
                                )
                            )
                        : filteredData.length &&
                          filteredData.map((s, index) => (
                            <div className="game-box" key={index}>
                              <div className="img-box">
                                <div className="overlay">
                                  <a
                                    onClick={() => {
                                      !isAuth
                                        ? handleModalShow(
                                            APP_CONST.AUTH_MODAL.LOGIN
                                          )
                                        : (window.location.href =
                                            s.redirectUrl);
                                    }}
                                    className="play-btn"
                                  >
                                    <img src={PlayBtn} />
                                  </a>
                                </div>
                                <img
                                  style={{ width: "10%" }}
                                  src={getImageUrl(s?.imgUrl)}
                                  alt={s?.alt}
                                />
                              </div>
                              <div className="game_name">{s?.name}</div>
                            </div>
                          ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}

      {showAuthModal && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModal}
        />
      )}
    </>
  );
};
export default GameType;
