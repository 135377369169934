import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { APP_CONST } from "../../../config/const";
import { loginUser } from "../../../redux/auth/actions";
import { Modal, Button, Form } from "react-bootstrap";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import WhatsappModal from "../../../assets/images/Whatsapp.svg";
import TelegramModal from "../../../assets/images/Telegram.svg";
import InstagramModal from "../../../assets/images/Instagram.svg";
import FacebookModal from "../../../assets/images/facebook-512.svg";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
      centered
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>Sign In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart d-none">
            <img src={LoginLeftPart} className="signin" />
          </div> */}
          <div className="Login-rightPart">
            <div className="form-steps">
              <Form>
                <Form.Group className="form-group" controlId="formBasicName">
                  <Form.Label>
                    Username <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Username*"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    Password <small>*</small>
                  </Form.Label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <IoEyeOffSharp /> : <IoEyeSharp />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Sign In
                </Button>
              </Form>

              <div className="social-icon-modal">
                <ul>
                  <li>
                    <img src={FacebookModal} />
                  </li>
                  <li>
                    <img src={TelegramModal} />
                  </li>
                  <li>
                    <img src={InstagramModal} />
                  </li>
                  <li>
                    <img src={WhatsappModal} />
                  </li>
                </ul>
              </div>

              <div>
                <a
                  className="form-link"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.FORGOT_PASSWORD);
                  }}
                >
                  Forgot Password
                </a>
              </div>

              <div className="sign-up">
                Don’t have an account? &nbsp;
                <a
                  href="#"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                  }}
                >
                  Register an Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
