import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import parsePhoneNumber from "libphonenumber-js";
import { sendOtpToMobile } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { APP_CONST } from "../../../config/const";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Toaster } from "../../../utils/toaster";
import PhoneInput from "react-phone-input-2";

import FacebookModal from "../../../assets/images/Whatsapp.svg";
import TelegramModal from "../../../assets/images/Telegram.svg";
import InstagramModal from "../../../assets/images/Instagram.svg";
import WhatsappModal from "../../../assets/images/facebook-512.svg";

const MobileNumberModal = ({ show, handleClose, handleModalShow }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const OTP = useSelector((state) => state?.app?.appDetails);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(searchParams.get("refferal"));
  const [agentCode, setAgentCode] = useState(searchParams.get("agent_code"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmitting(true);
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.mobile = phoneNumber.nationalNumber;
      payload.country_code = phoneNumber.countryCallingCode;
    }

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.error === false) {
          persistDetails(payload);

          if (OTP && OTP?.OTP === 0) {
            // navigate("/verify-number", {
            //   state: { referalCode, agentCode },
            // });
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.VERIFY_NUMBER);
          } else {
            // navigate("/register", {
            //   state: { referalCode, agentCode },
            // });
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
          }
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        setIsSubmitting(false);
      });
  };

  const persistDetails = (data) => {
    localStorage.setItem("mobile_details", JSON.stringify(data));
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal LoginImg SignUpImg"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
      centered
    >
      <Modal.Header closeButton onClick={handleClose} closeVariant="white">
        <Modal.Title>Verify Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart">
            <img src={LoginLeftPart} />
          </div> */}
          <div className="Login-rightPart">
            <div className="form-steps">
              <Form>
                {/* <Form.Label>
                  Phone <small>*</small>
                </Form.Label> */}
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    countryCodeEditable={false}
                    onlyCountries={["in", "np"]}
                    inputStyle={{
                      display: "block",
                      width: "100%",
                      lineHeight: 1.5,
                      color: "#fff",
                      padding: "0.7rem 0.75rem",
                      fontSize: "0.86rem",
                      minHeight: "41px",
                      paddingLeft: "50px",
                    }}
                    onChange={(phone) => {
                      // alert("phone", phone);
                      // alert(phone);
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Loading.." : "Next"}
                </Button>
              </Form>

              <div className="social-icon-modal">
                <ul>
                  <li>
                    <img src={FacebookModal} />
                  </li>
                  <li>
                    <img src={TelegramModal} />
                  </li>
                  <li>
                    <img src={InstagramModal} />
                  </li>
                  <li>
                    <img src={WhatsappModal} />
                  </li>
                </ul>
              </div>

              <div className="sign-up">
                Already a member?
                <br />
                <a
                  href="#"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  Sign In here
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MobileNumberModal;
