import React, { useEffect, useState } from "react";
import { APP_CONST } from "../config/const";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/images/logo.png";
import BeforeLeftBar from "../views/components/BeforeLeftBar";
import ModalWrapper from "./ModalWrapper";
import { useSelector } from "react-redux";

const Header = ({ agentCode = "" }) => {
  const { appDetails } = useSelector((state) => state.app);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);

  useEffect(() => {
    if (agentCode) {
      setShowAuthModal(true);
      setDefaultModal(APP_CONST.AUTH_MODAL.REGISTER);
    }
  }, [agentCode]);

  const handleModalShow = (modalName) => {
    setShowAuthModal(true);
    setDefaultModal(modalName);
  };

  return (
    <header className="header beforeheader">
      <div className="top_head max-1200w">
        <div className="head-content">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              {/* <img src={Logo} alt="Logo" /> */}
            </a>
          </div>

          <div className="d-flex sidebarBtn">
            <BeforeLeftBar setShowAuthModal={setShowAuthModal} />
          </div>

          <div className="middle-part d-none">
            <Navbar expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link
                      href="/sportsbook?bt-path=%2Flive"
                      className="exchange-nav"
                    >
                      Live Betting
                    </Nav.Link>
                    <Nav.Link href="/" className="exchange-nav">
                      Sportsbook
                    </Nav.Link>
                    <Nav.Link href="/sign-in">Slots</Nav.Link>
                    <Nav.Link href="/sign-in">Casino</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div className="headerRight">
            <div className="d-flex align-items-center ">
              <Button
                variant="primary"
                onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}
                className="btn_signin"
              >
                Sign In
              </Button>
              <Button
                variant="primary"
                onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.REGISTER)}
                className="btn_signup"
              >
                Register
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showAuthModal && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModal}
          agentCode={agentCode}
        />
      )}
    </header>
  );
};

export default Header;
