import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import SliderSection from "../../../components/sliderSection";
import { slotsData } from "../../../../lib/data/slots.data";

import Lobby from "../../../../assets/images/icons/casino/Lobby.png";
import LiveCasino from "../../../../assets/images/icons/casino/LiveCasino.png";
import CasinoGames from "../../../../assets/images/icons/casino/CasinoGames.png";
import VirtualSports from "../../../../assets/images/icons/casino/VirtualSports.png";
import TVGames from "../../../../assets/images/icons/casino/TvGame.png";
import Poker from "../../../../assets/images/icons/casino/Poker.png";

import { casinoTabs } from "../../../../lib/data";
import VirtualData from "../../../components/homeSliders/VirtualData";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";

const CasinoGame = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const location = useLocation();

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const [showSlider, setShowSlider] = useState({
    show: true,
    category: "Lobby",
    showMultipleSlides: {
      "Casino Games": true,
      "Live Casino": true,
      "Top Providers": true,
      Slots: true,
      "Virtual Sports": true,
      // "TV Games": true,
      // Poker: true,
    },
  });
  const [sliderPageNumber, setSliderPageNumber] = useState({
    "Casino Games": 1,
    "Live Casino": 1,
    "Top Providers": 1,
    Slots: 1,
    "Virtual Sports": 1,
    "TV Games": 1,
    Poker: 1,
  });
  const [activeCategory, setActiveCategory] = useState("Lobby");
  useEffect(() => {
    if (location?.state?.tabName) {
      setActiveCategory(location?.state?.tabName);
    }
  }, [location]);
  const categoriesTab = [
    { tabname: "Lobby", tabImg: Lobby },
    { tabname: "Live Casino", tabImg: LiveCasino },
    { tabname: "Casino Games", tabImg: CasinoGames },
    { tabname: "TV Games", tabImg: TVGames },
    { tabname: "Virtual Sports", tabImg: VirtualSports },
    { tabname: "Poker", tabImg: Poker },
  ];
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>

      <main className="main Beforehomepage">
        <div className="PageContainer ">
          <div className="casinogamepageSection">
            <div className="casino-category filters ">
              <ul>
                {categoriesTab.map((tab, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setShowSlider((p) => ({
                        category: tab.tabname,
                        show: true,
                        ...p,
                      }));
                      setActiveCategory(tab.tabname);
                    }}
                  >
                    <a
                      href="#"
                      style={
                        activeCategory === tab.tabname
                          ? {
                              color: "#ffffff",
                              background: "#2f4553",
                            }
                          : {}
                      }
                    >
                      {/* {<img src={Lobby} />} */}

                      {/* {imgTabs} */}
                      <img src={tab.tabImg} />
                      {tab.tabname}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <SliderSection
              isSearched={isSearched}
              activeCategory={activeCategory}
              data={slotsData}
              sliderPageNumber={sliderPageNumber}
              isAuth={isAuth}
              showSlider={showSlider}
              filteredData={filteredData}
              setSliderPageNumber={setSliderPageNumber}
              setShowSlider={setShowSlider}
            />
          </div>
        </div>
      </main>

      <div>{isAuth ? <Footer /> : <BeforeLoginFooter />}</div>
    </div>
  );
};

export default CasinoGame;
